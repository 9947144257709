/**
 * Es6 module for helper component.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @changelog
 * - 0.0.1 basic function and structure
 */

/**
 * Get basic window document dom node.
 *
 * @function
 * @returns {Object|null} The current document or null (in isomorph environments)
 */
function getDocumentDomNode() {
    if (typeof window === 'undefined') {
        return null;
    }
    return window.document;
}


/**
 * Get dom node element by tag name.
 *
 * @function
 * @param {string} name - The elements tag name
 * @returns {Object|null} The current element or null
 */
function getDomNodeByTagName(name) {
    const doc = getDocumentDomNode();
    return (doc && doc.getElementsByTagName(name)) || null;
}

/**
 * Get first dom node element by tag name.
 *
 * @function
 * @param {string} name - The elements tag name
 * @returns {Object|null} The current element or null
 */
function getFirstDomNodeByTagName(name) {
    const domNode = getDomNodeByTagName(name);
    return (domNode && domNode[0]) || null;
}

/**
 * Convenient helper to get head dom node.
 *
 * @function
 * @returns {Object|null} The current head dom element or null
 */
function getHeadDomNode() {
    return getFirstDomNodeByTagName('head');
}

/**
 * Create dom node element.
 *
 * @function
 * @param {string} name - The node element name type
 * @param {Object} attributes - Name/value mapping of the element attributes
 * @returns {Object|null} The created html object
 */
function createDomNode(name, attributes) {
    const doc = getDocumentDomNode();
    if (!doc) {
        return null;
    }

    const domNode = doc.createElement(name);

    // Check for attributes to set
    if (attributes) {
        Object.keys(attributes).forEach(function handleKey(key) {
            domNode.setAttribute(key, attributes[key]);
        });
    }

    // Return created node
    return domNode;
}

export {
    getDocumentDomNode,
    getDomNodeByTagName,
    getFirstDomNodeByTagName,
    getHeadDomNode,
    createDomNode
};
