/**
 * Handle client side file loading and take
 * care for performance due to async loading.
 *
 * @file
 * @module
 *
 * @author hello@ulrichmerkel.com (Ulrich Merkel), 2016
 * @version 0.0.1
 *
 * @requires loader/async
 *
 * @changelog
 * - 0.0.1 Basic functions and structure
 */
import loaderAsync from './loader/async';

// Define baseurl for js resource loading
const likearchitecture = window.likearchitecture || {
    baseUrl: '',
    modified: new Date().getTime()
};

// Register the service worker if available
// if (navigator.serviceWorker) {
//     navigator.serviceWorker.register(`${likearchitecture.baseUrl}/service-worker.bundle.js`).then(function() {
//         return navigator.serviceWorker.ready;
//     }).then(function(request) {
//         return request.active.postMessage(JSON.stringify({
//             modified: likearchitecture.modified
//         }));
//     }).catch(function(reason) {
//         console.warn(reason); // eslint-disable-line no-console
//     });
// }

// Load assets async to improve performance
loaderAsync.css(`${likearchitecture.baseUrl}/assets/css/app.css`);
loaderAsync.js(`${likearchitecture.baseUrl}/assets/js/client.bundle.js`);
